.jumbotron {
  margin-bottom: 0;
  margin: 0;
  border-radius: 0; }

.carousel-caption {
  height: 100%;
  padding: 0;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  display: flex !important;
  justify-content: center;
  align-items: center; }

.row {
  margin-left: 0;
  margin-right: 0; }

body {
  font-family: "Helvetica Neue", "Helvetica", "Arial", sans-serif; }

a {
  color: #474749;
  text-decoration: none !important; }

.btn {
  border: 0;
  border-radius: unset; }
  .btn.btn-primary {
    color: white;
    background-color: #756b5f; }
    .btn.btn-primary a {
      color: white; }

footer {
  font-size: 16px; }
  footer .FooterLogo {
    padding: 60px 0 40px 0; }
    footer .FooterLogo img {
      width: 20%;
      max-width: 100%; }
    @media only screen and (max-width: 576px) {
      footer .FooterLogo img {
        max-width: 80%; } }
  footer .FooterLinks,
  footer .FooterPortfolioLinks {
    display: flex;
    justify-content: space-evenly;
    align-items: center; }
    footer .FooterLinks a,
    footer .FooterPortfolioLinks a {
      text-align: center; }
    @media only screen and (max-width: 576px) {
      footer .FooterLinks a,
      footer .FooterPortfolioLinks a {
        width: 20%;
        font-size: 12px; } }
  footer .FooterLinksRow {
    display: flex;
    flex-direction: column;
    align-items: center; }
  footer .FooterPortfolioLinks,
  footer .FooterLinks {
    max-width: 1300px;
    display: flex;
    flex-direction: row; }
  footer .FooterPortfolioLinks {
    padding: 20px 0 40px 0;
    font-size: 0.9em;
    text-transform: uppercase; }
  footer .FooterLinks {
    padding: 20px 0 40px 0;
    font-size: 0.9em;
    text-transform: uppercase;
    flex-wrap: wrap; }
    @media only screen and (max-width: 576px) {
      footer .FooterLinks {
        padding: 20px 0px 30px 0; } }
  footer .Copywrite {
    color: white;
    font-size: 0.8em;
    padding: 10px 0;
    background-color: #a2b9a5; }
    footer .Copywrite a {
      color: white;
      text-decoration: underline !important; }
    @media only screen and (max-width: 576px) {
      footer .Copywrite {
        font-size: 0.5em; } }

.navbar {
  background: white;
  background: white;
  transition: all 0.1s linear; }
  .navbar .navbar-toggler {
    border: 0;
    outline: 0; }
  .navbar .Logo {
    height: 26px;
    margin-left: 100px; }
  .navbar #LoginButtonMobile {
    display: none; }
  .navbar:not(.NavBarCollapsed) {
    background: white;
    transition: all 0.1s linear; }

.NavbarFadeIn {
  background: rgba(255, 255, 255, 0);
  transition: all 0.1s linear; }

@media only screen and (max-width: 576px) {
  .navbar .Logo {
    margin-left: 0; }
  .navbar #LoginButtonMobile {
    display: flex; }
  .navbar #LoginButton {
    display: none; } }

.Banner img {
  max-width: 100%; }

.teamCards {
  margin: 20px 0; }
  .teamCards .card {
    border: none;
    text-align: left; }
    .teamCards .card .card-body {
      padding: 0px; }
    .teamCards .card .card-title {
      color: #135b51;
      text-transform: uppercase;
      margin-bottom: 5px; }
  .teamCards .card:hover {
    cursor: pointer; }

.teamMobile {
  margin: 0; }
  .teamMobile .card {
    margin-bottom: 25px; }
    .teamMobile .card .card-title {
      margin-bottom: 5px; }
    .teamMobile .card .card-subtitle {
      font-size: 13px; }

.bioDiv {
  background-color: #a2b9a5a6;
  padding: 25px 45px;
  padding-top: 35px;
  margin: 25px 0px; }
  .bioDiv h2 {
    font-weight: 400;
    margin-bottom: 0;
    text-transform: uppercase; }
  .bioDiv h4 {
    font-style: italic;
    font-weight: 200; }

.bioDivMobile {
  background-color: #a2b9a5a6;
  padding: 15px 25px;
  padding-top: 20px;
  margin: 15px 0px; }
  .bioDivMobile h2 {
    font-size: 25px;
    font-weight: 400;
    margin-bottom: 0;
    text-transform: uppercase; }
  .bioDivMobile h4 {
    font-size: 20px;
    font-style: italic;
    font-weight: 200; }
  .bioDivMobile p {
    font-size: 14px; }

.Home .Intro {
  height: 100vh;
  width: 100vw;
  background-image: linear-gradient(rgba(255, 255, 255, 0.25), rgba(255, 255, 255, 0.25)), url("/static/images/homepageHeroImage.png");
  background-size: cover; }
  .Home .Intro .HeaderContent {
    padding-left: 15%; }
    @media only screen and (max-width: 576px) {
      .Home .Intro .HeaderContent {
        padding-left: 0; } }
  .Home .Intro .Banner .IntroImg {
    width: 350px;
    margin-bottom: 25px; }
  .Home .Intro .BannerLinks a {
    font-weight: 400;
    font-size: 14px;
    text-transform: uppercase;
    color: #000000;
    margin-bottom: 25px;
    margin-left: 20px; }
    @media only screen and (max-width: 850px) {
      .Home .Intro .BannerLinks a {
        margin: 10px 10px 10px 14px;
        font-size: 16px;
        padding: 16px 20px;
        background-color: rgba(255, 255, 255, 0.7); } }

.Home .IntroSection2 {
  height: 50vh;
  background-color: #ffffff;
  background-size: cover;
  color: #135b51; }
  .Home .IntroSection2 h2 {
    font-size: 26px; }
  .Home .IntroSection2 .h21 {
    text-align: center;
    font-weight: 300;
    font-size: 26px; }
    .Home .IntroSection2 .h21 span {
      font-weight: 500;
      font-style: italic; }
  .Home .IntroSection2 .h22 {
    font-weight: 300;
    font-size: 26px; }
    .Home .IntroSection2 .h22 span {
      font-style: italic; }
  @media only screen and (max-width: 576px) {
    .Home .IntroSection2 {
      height: 70vh; }
      .Home .IntroSection2 h2 {
        text-align: center;
        font-size: 32px; } }

.Home .IntroSection3 {
  height: 55vh;
  background-size: cover;
  color: #135b51;
  padding-top: 60px; }
  .Home .IntroSection3 .IntroRow3 {
    padding-top: 30px;
    padding-bottom: 70px;
    display: flex;
    justify-content: center;
    align-items: center; }
  .Home .IntroSection3 h2 {
    font-weight: 300;
    text-align: center;
    font-size: 60px;
    letter-spacing: 2px; }
  .Home .IntroSection3 p {
    font-weight: 100;
    font-size: 26px; }
    .Home .IntroSection3 p span {
      font-style: italic; }
    .Home .IntroSection3 p #sm-mark {
      font-weight: normal; }
  @media only screen and (max-width: 576px) {
    .Home .IntroSection3 {
      height: auto; }
      .Home .IntroSection3 h2 {
        text-align: center; } }

.Contact .Intro {
  background-image: url("/static/images/modernLivingroom.png");
  background-size: cover;
  border-radius: unset;
  padding: 4rem 0; }
  .Contact .Intro .InnerBanner {
    width: 100vw;
    background-color: rgba(255, 255, 255, 0.85);
    padding: 30px;
    padding-left: 30px; }
    .Contact .Intro .InnerBanner .textColumn {
      display: block;
      margin-top: auto;
      margin-bottom: auto; }
    .Contact .Intro .InnerBanner h1,
    .Contact .Intro .InnerBanner p {
      color: #135b51;
      font-weight: 300; }
    .Contact .Intro .InnerBanner h1 {
      font-size: 45px;
      margin-top: auto;
      margin-bottom: 15px; }
    .Contact .Intro .InnerBanner p {
      font-size: 23px; }

.Contact .ContactFormWrapper {
  background-color: #b5baac;
  padding: 40px; }
  .Contact .ContactFormWrapper input {
    border-radius: unset; }
  .Contact .ContactFormWrapper button {
    text-transform: uppercase;
    background-color: #e6e6e6;
    color: #525252;
    font-weight: 400;
    font-size: 14px; }

.Contact .ContactDetails {
  padding: 40px;
  border-bottom: 1px solid #707070; }
  .Contact .ContactDetails p {
    margin: 0;
    color: #215879;
    text-align: center; }

@media only screen and (min-width: 768px) {
  .Contact .Intro .InnerBanner {
    padding: 50px;
    padding-left: 80px; } }

.Vision .Intro {
  background-image: url("/static/images/houseBush.png");
  background-size: cover;
  background-position: center;
  border-radius: unset;
  padding: 4rem 0; }

.Vision .InnerBanner {
  width: 100vw;
  background-color: rgba(255, 255, 255, 0.85);
  padding: 50px;
  padding-left: 80px; }
  .Vision .InnerBanner h1 {
    color: #135b51;
    font-size: 45px;
    font-weight: 300;
    margin-bottom: 15px; }
  .Vision .InnerBanner .paraDiv {
    display: flex; }
  .Vision .InnerBanner p {
    color: #135b51;
    font-size: 23px;
    margin: 0; }
  .Vision .InnerBanner #wherePara {
    font-weight: 300; }
  .Vision .InnerBanner #howPara {
    font-weight: 400; }
  .Vision .InnerBanner #blueRectangleImg {
    height: 7px; }
  @media only screen and (max-width: 767px) {
    .Vision .InnerBanner .anchor-wrapper {
      display: flex;
      justify-content: center; } }
  .Vision .InnerBanner #anchorImg {
    height: 270px; }
    @media only screen and (min-width: 1000px) {
      .Vision .InnerBanner #anchorImg {
        margin-left: 10vw; } }
    @media only screen and (max-width: 767px) {
      .Vision .InnerBanner #anchorImg {
        margin-top: 20px;
        margin-bottom: 20px; } }
    @media only screen and (max-width: 576px) {
      .Vision .InnerBanner #anchorImg {
        height: 200px; } }

.Vision .visionAboutSection {
  padding-top: 60px;
  padding-bottom: 100px;
  margin-bottom: 50px;
  border-bottom: 1px solid #707070; }
  .Vision .visionAboutSection p {
    color: #214459;
    font-size: 20px;
    font-weight: 400;
    letter-spacing: 1px;
    text-align: left;
    margin-bottom: 25px; }
  .Vision .visionAboutSection #creatingParagraph {
    font-weight: 400; }

.Portfolio {
  font-family: "Helvetica Neue", "Helvetica", "Arial", sans-serif; }
  .Portfolio .Intro {
    background-image: url("/static/images/modernLivingroom.png");
    background-size: cover;
    background-position: center;
    border-radius: unset;
    padding: 4rem 0; }
    .Portfolio .Intro .InnerBanner {
      width: 100vw;
      background-color: rgba(255, 255, 255, 0.85);
      padding: 50px;
      padding-left: 80px; }
      .Portfolio .Intro .InnerBanner h1 {
        color: #135b51;
        font-size: 45px;
        font-weight: 300;
        margin-bottom: 15px; }
      .Portfolio .Intro .InnerBanner .paraDiv {
        display: flex; }
      .Portfolio .Intro .InnerBanner p {
        color: #135b51;
        font-size: 23px;
        margin: 0; }
      .Portfolio .Intro .InnerBanner #greatPlaces {
        font-weight: 300; }
      .Portfolio .Intro .InnerBanner #realPeople {
        font-weight: 400; }
  .Portfolio .carouselSection {
    padding-top: 50px;
    padding-bottom: 50px;
    margin-bottom: 20px; }
    .Portfolio .carouselSection .carouselTileRows {
      align-items: flex-start;
      justify-content: space-evenly; }
      .Portfolio .carouselSection .carouselTileRows .CarouselTile {
        margin-bottom: 20px; }
        .Portfolio .carouselSection .carouselTileRows .CarouselTile .CarouselItem {
          background: #756b5f;
          position: relative; }
          .Portfolio .carouselSection .carouselTileRows .CarouselTile .CarouselItem .SlideImage1 {
            opacity: 0.5; }
          .Portfolio .carouselSection .carouselTileRows .CarouselTile .CarouselItem .portfolioImgOverlay {
            font-weight: 300;
            letter-spacing: 2px; }
          .Portfolio .carouselSection .carouselTileRows .CarouselTile .CarouselItem .portfolioDescriptionSlide {
            font-weight: 300;
            font-size: 16px;
            color: black; }
          .Portfolio .carouselSection .carouselTileRows .CarouselTile .CarouselItem h3 {
            top: 50%;
            transform: translateY(-50%);
            position: absolute; }
          .Portfolio .carouselSection .carouselTileRows .CarouselTile .CarouselItem .annadelLogo,
          .Portfolio .carouselSection .carouselTileRows .CarouselTile .CarouselItem .mosaicLogo,
          .Portfolio .carouselSection .carouselTileRows .CarouselTile .CarouselItem .sseLogo,
          .Portfolio .carouselSection .carouselTileRows .CarouselTile .CarouselItem .baileyFarmLogo,
          .Portfolio .carouselSection .carouselTileRows .CarouselTile .CarouselItem .capitolYardsLogo,
          .Portfolio .carouselSection .carouselTileRows .CarouselTile .CarouselItem .cataniaLogo,
          .Portfolio .carouselSection .carouselTileRows .CarouselTile .CarouselItem .capstoneLogo {
            width: 60%; }
        .Portfolio .carouselSection .carouselTileRows .CarouselTile .NameTile {
          font-size: 15px;
          font-weight: 600;
          text-transform: uppercase;
          margin-left: 20px;
          margin-top: 15px;
          margin-bottom: 0; }
        .Portfolio .carouselSection .carouselTileRows .CarouselTile .LocationTile {
          font-size: 16px;
          font-weight: 300;
          margin-left: 20px;
          margin-top: 0; }
        .Portfolio .carouselSection .carouselTileRows .CarouselTile .ButtonTile {
          float: right;
          margin-right: 20px;
          padding: 10px 14px;
          background-color: #756b5f;
          color: #ffffff;
          font-size: 16px;
          font-weight: 300; }
        @media only screen and (max-width: 576px) {
          .Portfolio .carouselSection .carouselTileRows .CarouselTile .CarouselItem .portfolioDescriptionSlide {
            font-size: 12px; }
          .Portfolio .carouselSection .carouselTileRows .CarouselTile .NameTile,
          .Portfolio .carouselSection .carouselTileRows .CarouselTile .LocationTile {
            font-size: 12px;
            margin-left: 0; }
          .Portfolio .carouselSection .carouselTileRows .CarouselTile .ButtonTile {
            margin-right: unset;
            padding: 12px 12px;
            font-size: 13px; } }
  .Portfolio .line {
    margin-bottom: 20px;
    border-bottom: 1px solid #707070; }

.Login .imgHeader {
  background-size: cover;
  background-repeat: no-repeat;
  background-color: #a2b9a5;
  border-radius: unset; }
  .Login .imgHeader img {
    height: 20vh; }

.Login .line {
  margin-top: 20px;
  margin-bottom: 20px;
  border-bottom: 1px solid #707070; }

.privacyPolicyMain {
  font-family: "Helvetica Neue", "Helvetica", "Arial", sans-serif; }
  .privacyPolicyMain .imgHeader {
    background-size: cover;
    background-repeat: no-repeat;
    background-color: #a2b9a5;
    border-radius: unset; }
    .privacyPolicyMain .imgHeader img {
      height: 20vh; }
  .privacyPolicyMain .policyText {
    margin-top: 2em;
    color: #707070;
    text-align: left;
    font-size: 1.1em;
    background-color: #ffffff; }
    .privacyPolicyMain .policyText h1 {
      text-transform: uppercase;
      font-size: 2.2em;
      margin-top: 0.5em;
      padding-bottom: 0.25em; }
    .privacyPolicyMain .policyText h2 {
      font-size: 2em;
      margin-top: 0.5em;
      padding-bottom: 0.25em; }
    .privacyPolicyMain .policyText h3 {
      font-size: 1.9em;
      margin-top: 0.5em;
      padding-bottom: 0.25em; }
    .privacyPolicyMain .policyText h4 {
      font-size: 1.7em;
      margin-top: 0.5em;
      padding-bottom: 0.25em; }
  .privacyPolicyMain .line {
    margin-top: 50px;
    margin-bottom: 20px;
    border-bottom: 1px solid #707070; }

.termsServiceMain {
  font-family: "Helvetica Neue", "Helvetica", "Arial", sans-serif; }
  .termsServiceMain .imgHeader {
    background-size: cover;
    background-repeat: no-repeat;
    background-color: #a2b9a5;
    border-radius: unset; }
    .termsServiceMain .imgHeader img {
      height: 20vh; }
  .termsServiceMain .termsText {
    margin-top: 2em;
    color: #707070;
    text-align: left;
    font-size: 1.1em;
    background-color: #ffffff; }
    .termsServiceMain .termsText h1 {
      text-transform: uppercase;
      font-size: 2.2em;
      margin-top: 0.5em;
      padding-bottom: 0.25em; }
    .termsServiceMain .termsText h2 {
      font-size: 2em;
      margin-top: 0.5em;
      padding-bottom: 0.25em; }
    .termsServiceMain .termsText h3 {
      font-size: 1.9em;
      margin-top: 0.5em;
      padding-bottom: 0.25em; }
    .termsServiceMain .termsText h4 {
      font-size: 1.7em;
      margin-top: 0.5em;
      padding-bottom: 0.25em; }
  .termsServiceMain .line {
    margin-top: 50px;
    margin-bottom: 20px;
    border-bottom: 1px solid #707070; }

.Page.Team .Intro {
  background-image: url("/static/images/houseBush.png");
  background-size: cover;
  background-position: center;
  border-radius: unset;
  padding: 4rem 0; }

.Page.Team .InnerBanner {
  width: 100vw;
  background-color: rgba(255, 255, 255, 0.85);
  padding: 50px;
  padding-left: 80px; }
  .Page.Team .InnerBanner h1 {
    color: #135b51;
    font-size: 45px;
    font-weight: 300;
    margin-bottom: 15px; }
  .Page.Team .InnerBanner .paraDiv {
    display: flex; }
  .Page.Team .InnerBanner p {
    color: #135b51;
    font-size: 23px;
    margin: 0; }
  .Page.Team .InnerBanner #wherePara {
    font-weight: 300; }
  .Page.Team .InnerBanner #howPara {
    font-weight: 400; }

.Page.Team .TeamImagesBioSection {
  padding: 40px 0; }

.Page.Team .ImageTile {
  display: flex;
  justify-content: center; }
  .Page.Team .ImageTile img {
    max-height: 400px;
    max-width: 400px; }
  .Page.Team .ImageTile .card {
    max-width: 400px;
    border: none; }
    .Page.Team .ImageTile .card .card-img {
      max-height: 400px;
      max-width: 400px; }

.Page.Team .reviewsSection {
  background-image: linear-gradient(to bottom right, #135b51, #a2b9a5);
  padding-top: 50px;
  padding-bottom: 50px; }
  .Page.Team .reviewsSection .reviewsRow {
    justify-content: center; }
    .Page.Team .reviewsSection .reviewsRow .reviewCol {
      text-align: center; }
      .Page.Team .reviewsSection .reviewsRow .reviewCol h2 {
        color: #ffffff;
        font-weight: 300;
        font-size: 30px;
        letter-spacing: 1.2px;
        margin-bottom: 0px;
        padding-bottom: 25px; }
      .Page.Team .reviewsSection .reviewsRow .reviewCol hr {
        border-top: 2px solid #ffffff;
        opacity: 52%;
        margin-bottom: 30px;
        margin-top: 0px; }
      .Page.Team .reviewsSection .reviewsRow .reviewCol .reviewsCarouselDiv .reviewNameDiv {
        padding-top: 25px;
        padding-bottom: 25px;
        align-items: center; }
        .Page.Team .reviewsSection .reviewsRow .reviewCol .reviewsCarouselDiv .reviewNameDiv .reviewName {
          font-size: 20px;
          font-weight: 600; }
        .Page.Team .reviewsSection .reviewsRow .reviewCol .reviewsCarouselDiv .reviewNameDiv .leftArrow {
          transform: rotate(90deg); }
        .Page.Team .reviewsSection .reviewsRow .reviewCol .reviewsCarouselDiv .reviewNameDiv .rightArrow {
          transform: rotate(-90deg); }
        .Page.Team .reviewsSection .reviewsRow .reviewCol .reviewsCarouselDiv .reviewNameDiv .leftArrow:hover,
        .Page.Team .reviewsSection .reviewsRow .reviewCol .reviewsCarouselDiv .reviewNameDiv .rightArrow:hover {
          cursor: pointer; }
      .Page.Team .reviewsSection .reviewsRow .reviewCol .reviewsCarouselDiv .reviewTextDiv p {
        text-align: left;
        line-height: 24px; }
      .Page.Team .reviewsSection .reviewsRow .reviewCol .reviewsCarouselDiv .reviewLogoRow .ratingLogoDiv {
        padding-top: 10px;
        padding-bottom: 10px; }
        .Page.Team .reviewsSection .reviewsRow .reviewCol .reviewsCarouselDiv .reviewLogoRow .ratingLogoDiv .ratingLogo {
          width: 35px; }
      .Page.Team .reviewsSection .reviewsRow .reviewCol .reviewsCarouselDiv .reviewLogoRow .numberRating {
        font-size: 15px;
        font-weight: 500; }
  .Page.Team .reviewsSection img {
    padding-top: 30px;
    width: 100%; }

@media only screen and (max-width: 374px) {
  .Page.Team .reviewsSection .reviewsRow .reviewCol .reviewsCarouselDiv .reviewNameDiv .reviewName {
    font-size: 18px; }
  .Page.Team .teamDesktop {
    display: none; }
  .Page.Team .teamMobile {
    display: flex; } }

@media only screen and (min-width: 375px) {
  .Page.Team .teamDesktop {
    display: none; }
  .Page.Team .teamMobile {
    display: flex; } }

@media only screen and (min-width: 768px) {
  .Page.Team .reviewsSection .reviewsRow .reviewCol .reviewsCarouselDiv .reviewTextDiv p {
    text-align: center; }
  .Page.Team .reviewsSection .reviewsRow .imgCol {
    display: flex;
    align-items: center; }
  .Page.Team .teamDesktop {
    display: flex; }
  .Page.Team .teamMobile {
    display: none; } }

@media only screen and (min-width: 992px) {
  .Page.Team .reviewsSection {
    padding: 60px; }
    .Page.Team .reviewsSection .reviewsRow .reviewCol {
      height: 723px; }
      .Page.Team .reviewsSection .reviewsRow .reviewCol h2 {
        font-size: 40px; }
      .Page.Team .reviewsSection .reviewsRow .reviewCol .reviewsCarouselDiv .reviewNameDiv {
        padding-top: 30px;
        padding-bottom: 30px; }
      .Page.Team .reviewsSection .reviewsRow .reviewCol .reviewsCarouselDiv .reviewTextDiv {
        padding: 0 10px; }
  .Page.Team .teamDesktop {
    display: flex; }
  .Page.Team .teamMobile {
    display: none; } }

@media only screen and (min-width: 1200px) {
  .Page.Team .reviewsSection {
    padding: 100px 75px; }
    .Page.Team .reviewsSection .reviewsRow .reviewCol {
      padding: 0 38px;
      height: 708px; }
      .Page.Team .reviewsSection .reviewsRow .reviewCol h2 {
        letter-spacing: 2px;
        padding-bottom: 30px; }
      .Page.Team .reviewsSection .reviewsRow .reviewCol .reviewsCarouselDiv .reviewNameDiv {
        padding: 35px 0; }
      .Page.Team .reviewsSection .reviewsRow .reviewCol .reviewsCarouselDiv .reviewTextDiv {
        padding: 0 25px; }
    .Page.Team .reviewsSection .reviewsRow .imgCol img {
      height: 90%;
      width: 95%; }
  .Page.Team .teamDesktop {
    display: flex; }
  .Page.Team .teamMobile {
    display: none; } }

.no-padding {
  padding: 0; }

.flex-center {
  display: flex;
  justify-content: center;
  align-items: center; }

.flex-column {
  flex-direction: column; }

.flex-row {
  flex-direction: row; }

.WithHeaderOffset {
  margin-top: 57px; }

.width-100 {
  width: 100%; }

.aboutLink {
  color: #00b5e2; }
